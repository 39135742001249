import React, { useState, useEffect } from "react";
const LandingPageHeaderNavIcon = ({item, include_images, key, selectedItem, isJapanese}) => {
  const [isHovered, setIsHovered] = useState(false);

  
  // onMouseOut={() => setOver(false)}

  const renderIcon = () => {
    if (include_images) {
      if (isHovered) {
        return <img src={`/images/landing/ico-${item.id}-hover.svg`} />
      }
      return <img src={`/images/landing/ico-${item.id === selectedItem ? `${item.id}-active` : item.id}.svg`} />
    }
   return <></>;
  }

  const renderHeaderItem = () => {
    return(
      <div
        className={`landing-page-nav-item ${item.id === selectedItem ? 'active' : ''} ${isJapanese ? 'landing-page-nav-item-less-margin' : ''}`}
        key={`header-item-${item.id}-${key}`}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        <a href={`#${item.id}`}>
          <div className="landing-page-nav-item-block">
            {renderIcon()}
            {

            }
            <div className="body1 english" dangerouslySetInnerHTML={{__html: item.title}}/>
            <div className="body1 japanese landing-hidden" dangerouslySetInnerHTML={{__html: item.title_japanese}} style={{fontSize: "12px"}}/>
          </div>
        </a>
      </div>
    );
  }

  return (
    <>
      {renderHeaderItem()}
    </>
  );
};

export default LandingPageHeaderNavIcon;
