import React, { useState, useEffect } from "react";
import LandingPageHeaderNavIcon from "./LandingPageHeaderNavIcon";

const LandingPageHeaderNav = ({header_sections, include_images, is_japanese}) => {
  const [selectedItem, setSelectedItem] = useState(header_sections[0].id);
  const [unchanged, setUnchanged] = useState('');
  const [isJapanese, setIsJapanese] = useState(is_japanese);
  const [isLanguageHovered, setIsLanguageHovered] = useState(false)

  const toggleJapanese = () => {
    let el_jp = document.getElementsByClassName("japanese");
    let el_en = document.getElementsByClassName("english");
    for (let el of el_jp) {
      el.classList.remove('landing-hidden');
    }
    for (let el of el_en) {
      el.classList.add('landing-hidden');
    }
    setIsJapanese(true);
  };
  const toggleEnglish = () => {
    let el_jp = document.getElementsByClassName("japanese");
    let el_en = document.getElementsByClassName("english");
    for (let el of el_jp) {
      el.classList.add('landing-hidden');
    }
    for (let el of el_en) {
      el.classList.remove('landing-hidden');
    }
    setIsJapanese(false);
  };

  useEffect(() => {
    if (is_japanese) {
      toggleJapanese();
    }
  }, [unchanged]);

  useEffect(() => {
    const onScroll = e => {
      let stillLooking = true;
      let currentActive = selectedItem;
      if ((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight) {
        setSelectedItem(header_sections[header_sections.length - 1].id);
      } else {
        for (let index = header_sections.length - 1; index >= 0; index--) {
          let element = document.getElementById(header_sections[index].id);
          if (element && element.getBoundingClientRect().top < 1) {
            if (stillLooking) {
              if (currentActive !== header_sections[index].id) {
                setSelectedItem(header_sections[index].id);
              }
            }
            stillLooking = false;
          }
        }
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [selectedItem]);

  const renderHeaderItems = () => {
    const renderedHeaderItems = header_sections.map((item, key) =>
      <LandingPageHeaderNavIcon item={item} key={key} include_images={include_images} selectedItem={selectedItem} isJapanese={isJapanese} />
      // <div className={`landing-page-nav-item ${item.id === selectedItem ? 'active' : ''} ${isJapanese ? 'landing-page-nav-item-less-margin' : ''}`} key={`header-item-${item.id}-${key}`}>
      //   <a href={`#${item.id}`}>
      //     <div className="landing-page-nav-item-block">
      //       {
      //         include_images ?
      //         <img src={`/images/landing/ico-${item.id === selectedItem ? `${item.id}-active` : item.id}.svg`} />
      //         :
      //         <></>
      //       }
      //       <div className="body1 english" dangerouslySetInnerHTML={{__html: item.title}}/>
      //       <div className="body1 japanese landing-hidden" dangerouslySetInnerHTML={{__html: item.title_japanese}} style={{fontSize: "12px"}}/>
      //     </div>
      //   </a>
      // </div>
    );
    renderedHeaderItems.push(
      <>
        <div className="landing-page-nav-item" style={{borderLeft: "1px solid #53565A", margin: "0px"}}></div>
        <div className="landing-page-nav-item english" onMouseOver={() => setIsLanguageHovered(true)} onMouseOut={() => setIsLanguageHovered(false)}>
          <a onClick={toggleJapanese} style={{cursor: 'pointer'}}>
            <div className="landing-page-nav-item-block">
              {
                isLanguageHovered ?
                <img src={`/images/landing/ico-world-hover.svg`} />
                :
                <img src={`/images/landing/ico-world.svg`} />
              }
              <div className="body1">日本語</div>
            </div>
          </a>
        </div>
        <div className="landing-page-nav-item japanese landing-hidden" onMouseOver={() => setIsLanguageHovered(true)} onMouseOut={() => setIsLanguageHovered(false)}>
          <a onClick={toggleEnglish} style={{cursor: 'pointer'}}>
            <div className="landing-page-nav-item-block">
              {
                isLanguageHovered ?
                <img src={`/images/landing/ico-world-hover.svg`} />
                :
                <img src={`/images/landing/ico-world.svg`} />
              }
              <div className="body1">English</div>
            </div>
          </a>
        </div>
      </>
    );
    return renderedHeaderItems;
  };

  return (
    <div className="hidden-sm hidden-xs">
      <div className="landing-page-nav">
        {renderHeaderItems()}
      </div>
    </div>
  );
};

export default LandingPageHeaderNav;
